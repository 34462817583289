import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { AchieveTheme, Grid } from '@achieve/ascend'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { TitleDivider } from 'components/TitleDivider'
import { Carousel } from 'components/Carousels'
import { getSlug } from 'utils/conversions'
import { CONTENT_TYPES } from 'services/contentful/constants'
import { LEARN_ROUTE } from 'constants/navigation'
import { PlayButtonBackground } from 'components/PlayButtonBackground'
import { mainProductConfig } from 'services/contentful/helpers'
import { useContext } from 'react'
import { LayoutContext } from 'providers/LayoutProvider'

function ArticleCard({
  item: { title, subTitle, link, category, categoryLink, image, isVideo },
  index,
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const isXS = useViewportSmallerThan(BREAKPOINTS.md)
  const { state: { siteEnv } = {} } = useContext(LayoutContext)
  const CardContent = (
    <AchieveLink
      href={link}
      track={{
        list_name: `ARTICLE LINK`,
        position: index,
        click_id: `${mainProductConfig[siteEnv || 'achieve']} Web | ${title}`,
        click_text: title,
        click_url: link,
        nav_link_section: 'Article',
        track_event: 'link_click',
        click_type: 'Link Click',
        event_action: 'link_click',
        event_type: 'link_click',
      }}
      className={'text-black hover:no-underline hover:data-[testid="card-title"]:underline'}
      underline="hover"
      data-testid="article-card-link"
    >
      <div>
        {image && (
          <div>
            <div
              className={
                'relative max-h-full max-w-full w-[315px] h-[204px] [&_span]:box-border [&_span]:block [&_span]:overflow-hidden [&_span]:w-[initial] [&_span]:h-[initial] [background:none] [&_span]:opacity-100 [&_span]:absolute [&_span]:m-0 [&_span]:p-0 [&_span]:border-0 [&_span]:inset-0 [&_img]:absolute [&_img]:box-border [&_img]:block [&_img]:w-0 [&_img]:h-0 [&_img]:min-w-full [&_img]:max-w-full [&_img]:min-h-full [&_img]:max-h-full [&_img]:object-cover [&_img]:m-auto [&_img]:p-0 [&_img]:border-[none] [&_img]:inset-0 md:w-[390px] md:h-[212px] [&_img]:object-[center_center] [&_img]:rounded-[12px]'
              }
            >
              <MediaImageStandard
                content={image}
                height={isXS ? 204 : 215}
                width={isXS ? 315 : 384}
              />
              {isVideo && <PlayButtonBackground fontSize={64} />}
            </div>
          </div>
        )}

        {!isMobile && category && categoryLink && (
          <Typography
            variant="bodySm"
            fontWeight="bold"
            className={'my-3 mx-0 text-[#5c708a] tracking-[0.25em] uppercase leading-[16.6px]'}
            data-testid="card-category"
            content={category}
          />
        )}
        {!isMobile && category && !categoryLink && (
          <Typography
            content={category}
            variant="bodySm"
            fontWeight="bold"
            component="h3"
            className={'my-3 mx-0 text-[#5c708a] tracking-[0.25em] uppercase leading-[16.6px]'}
            data-testid="card-category"
          />
        )}
        {title && (
          <Typography
            content={title}
            component="h3"
            className={'my-3 mx-0'}
            fontWeight="bold"
            data-testid="card-title"
          />
        )}
        {subTitle && (
          <Typography
            content={subTitle}
            variant={isMobile ? 'bodySm' : 'bodyLg'}
            className={'flex flex-col max-w-[315px]'}
            fontWeight="regular"
            data-testid="card-text"
          />
        )}
      </div>
    </AchieveLink>
  )

  return <Grid className={'flex flex-col max-w-[315px] md:max-w-sm'}>{CardContent}</Grid>
}

const ArticlesCarousel = ({ content }) => {
  const isSmallerXL = useViewportSmallerThan(BREAKPOINTS.xl)
  const sectionIdPrefix = `press-carousel-more-resources-carousel`
  const { title, link } = useMemoizedContentGetter(content, ['title', 'link'])
  const additionalConfiguration = content?.fields?.additionalConfiguration?.jsonContent

  const itemList = content?.fields?.relatedContentCollection?.items
    ?.filter((e) => {
      return ![CONTENT_TYPES.EXTERNAL_LINK_FUll, CONTENT_TYPES.LONG_FORM_CONTENT_FULL].includes(
        e?.__typename
      )
        ? false
        : true
    })
    ?.map((item) => {
      if (item?.__typename === CONTENT_TYPES.EXTERNAL_LINK_FUll) {
        return {
          sys: item?.sys,
          title: item?.title,
          subTitle: item?.subTitle,
          link: item?.link,
          category: item?.category,
          categoryLink: item?.categoryLink,
          image: item?.image?.mediaContent,
        }
      } else {
        let shortSubTitle = item?.hook
        return {
          sys: item?.sys,
          title: item?.title,
          subTitle: shortSubTitle,
          link: `${LEARN_ROUTE}/${getSlug(item?.primaryCategory)}/${item?.slug}`,
          category: item?.primaryCategory,
          categoryLink: `${LEARN_ROUTE}/${getSlug(item?.primaryCategory)}`,
          image: item?.mainImage?.mediaContent ?? item?.mainImage?.thumbnail,
          isVideo: !!item?.mainImage?.thumbnail,
        }
      }
    })

  const carouselEnabledByItemCount = itemList?.length > 3

  return (
    <>
      {itemList?.length > 0 && (
        <Section
          contain={false}
          className={'bg-white max-w-[1000vh] overflow-x-hidden py-[48px] lg:pr-0 lg:py-16'}
          backgroundColor={
            additionalConfiguration?.backgroundColor === 'blue'
              ? AchieveTheme?.ascend?.colors?.blue?.[50]
              : null
          }
          data-testid="ArticlesCarousel_section"
        >
          <TitleDivider
            className={'mb-12 lg:mb-16'}
            title={title}
            data-testid={`featured-press-carousel-title`}
            classNameTitleText="headingMd lg:displayLg font-medium"
            classNameSubtitleText="bodySm lg:displaySm font-light"
          />
          <Carousel
            carouselItems={itemList}
            className={
              'pl-0 lg:overflow-hidden xl:[transform:translateX(calc((100%-(1280px-(2*32px+64px)))/2))] xl:data-[arrow="true"]:[transform:none]'
            }
            classNameItems={
              'lg:first-child:pl-0 lg:group-data-[arrow="true"]:first-child:pl-[104px] lg:group-data-[arrow="true"]:first:pl-[104px] lg:group-data-[arrow="true"]:last:pl-[104px]'
            }
            itemComponent={ArticleCard}
            variant="auto"
            idPrefix={sectionIdPrefix}
            disableArrows={isSmallerXL || carouselEnabledByItemCount ? false : true}
            withIndicators={false}
            classNameControls={''}
          />
          {link && (
            <AchieveLink
              href={link.linkHref}
              track={{
                ...{
                  list_name: `ARTICLE LINK`,
                  click_id: link?.linkText?.json?.content[0]?.content[0]?.value,
                  click_text: link?.linkText?.json.content[0]?.content[0]?.value,
                  nav_link_section: 'Article',
                  track_event: 'link_click',
                  click_type: 'Link Click',
                  event_action: 'link_click',
                  event_type: 'link_click',
                },
                ...link?.event,
              }}
              fontWeight="bold"
              variant="bodySm"
              className={'flex justify-center mt-6'}
              underline="hover"
              data-testid="ArticlesCarousel_link"
            >
              {link?.linkText?.json?.content?.[0]?.content[0]?.value}
            </AchieveLink>
          )}
        </Section>
      )}
    </>
  )
}
export { ArticlesCarousel }
export default ArticlesCarousel
